<template>
  <div class="text-center w-100 mb-6">
    <TTBtn
      fab
      depressed
      color="white"
      elevation="1"
      large
      class="mb-2"
      data-test="aside-right-btn-template-list"
      :to="{ name : Names.R_MEETING_TPL_LIST }"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-edit
      </VIcon>
    </TTBtn>
    <div class="tt-text-caption tt-black--text text--lighten-2">
      Редактир.<br>
      шаблоны
    </div>
    <TTBtn
      fab
      depressed
      color="white"
      elevation="1"
      large
      data-test="aside-right-level-add"
      class="mt-6 mb-2 v-btn--aside"
      @click="$root.$emit('downloadReportMeeting')"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-arrow-to-bottom
      </VIcon>
    </TTBtn>
    <div class="tt-text-caption tt-black--text text--lighten-2">
      Выгрузить<br>
      отчет
    </div>
  </div>
</template>
<script>

export default {
  name: 'AppReportMeetingAsideRight',
  inject: ['Names'],
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
